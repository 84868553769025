<template>
    <div class="text-left px-4 px-lg-5 py-3">
        <h1>Disclaimer</h1>
        <br>
        <div>
            If you require any more information or have any questions about our site's disclaimer, please feel free to contact us.
        </div>
        <br>
        <h3>Body</h3>
        <br>
        <div>
            All the information on this website - https://stathub.gg - is published in good faith and for general information purpose only. StatHub.gg does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (StatHub.gg), is strictly at your own risk. StatHub.gg will not be liable for any losses and/or damages in connection with the use of our website.
            <br>
            <br>
            From our website / application, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone 'bad'.
            <br>
            <br>
            Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.
            <br>
            <br>
            StatHub.gg is a Third Party Product acknowledged and registered under Riot Games Inc., League of Legends, Legend of Runeterra, Teamfight Tactics and Valorant. Therefore we have the right to make contents using the allowed resources and follow the Legal Guidelines of Riot Games Inc. Detailed of these guidelines are found in Riot's Legal Jibber Jabber and the Riot Games Developers Portal.
            <br>
            <br>
            StatHub.gg isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of Riot Games or anyone officially involved in producing or managing League of Legends, Legends of Runeterra, Teamfight Tactics and Valorant. League of Legends, Legends of Runeterra, Teamfight Tactics and Valorant and Riot Games are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.
        </div>
        <br>
        <h3>Consent</h3>
        <br>
        <div>
            By using our website, you hereby consent to our disclaimer and agree to its terms.
        </div>
        <br>
        <h3>Update</h3>
        <br>
        <div>
            Should we update, amend or make any changes to this document, those changes will be prominently posted here.
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Disclaimer extends Vue {

    created(): void {
        this.$meta.use({
            title: 'Disclaimer | StatHub.gg',
            og: {
                image: '/img/icons/android-chrome-maskable-192x192.png',
                description: 'StatHub.gg - Disclaimer.'
            }
        })
    }

}
</script>
